


import React, { useEffect, useState } from 'react';
import { Button, Grid, Paper, Typography, CircularProgress, Card, CardContent } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import '../assets/css/emanagement.css';
import BackButton from './backbutton';
import { useLocation } from 'react-router-dom';
import { apiGetWaterManagement } from '../api/api.getwatermanagement';


import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';


ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

export default function WaterManagement() {
  const [waterManagementData, setWaterManagementData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const location = useLocation();
  const [chartData, setChartData] = useState([]);
  const [chartData2, setChartData2] = useState([]);

  const [dataType, setDataType] = useState('shift');
  const { locationId } = location.state || {};
  const [initialLoad, setInitialLoad] = useState(true);
  const [deviceNo, setDeviceNo] = useState("");
  const [pumpStatus, setPumpStatus] = useState(false); 

  const fetchData = async (locationId, dataType, deviceNo, showLoader = false) => {
    try {
      if (showLoader) {
        setIsLoading(true);
        setLoadingMessage(`Loading ${dataType.charAt(0).toUpperCase() + dataType.slice(1)}`);
      }
      const result = await apiGetWaterManagement(locationId, dataType, deviceNo);
      console.log("water flow data :",result.data.data)
      const chunkedData = result.data.data.slice(0,20)
      console.log("Water management data received:", chunkedData);
      const totalWaterFLow=chunkedData.filter(item=>item.totalWaterFLow)
      console.log("total water flow:",totalWaterFLow)

     
      const validData = chunkedData.filter(item => item.dateTimeRecvd);

      const sortedData = validData.sort((a, b) => {
        const dateA = new Date(a.dateTimeRecvd.split(' ')[0].split('-').reverse().join('-') + 'T' + a.dateTimeRecvd.split(' ')[1]);
        const dateB = new Date(b.dateTimeRecvd.split(' ')[0].split('-').reverse().join('-') + 'T' + b.dateTimeRecvd.split(' ')[1]);
        return dateA - dateB;
      });

      const xAxisLabels = sortedData.map((item) => {
        const dateParts = item.dateTimeRecvd.split(" ");
        const datePart = dateParts[0];
        const timePart = dateParts[1];
        const [day, month, year] = datePart.split("-");
        const [hours, minutes, seconds] = timePart.split(":");
        const date = new Date(year, month - 1, day, hours, minutes, seconds);
        return date.toLocaleString();
      });

      const updatedChartData = [
        {
          type: "bar",
          xAxis: xAxisLabels,
          data: sortedData.map((item) => parseFloat(item.totalWaterFlow)),
        },
      ];
      
      const updatedChartData2 = [
        {
          type: "bar",
          xAxis: xAxisLabels,
          data: sortedData.map((item) => parseFloat(item.totalWaterFlow)),
        },
      ];
      setChartData(updatedChartData);
      setChartData2(updatedChartData2);

      if (showLoader) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching water management data:", error);
      setError("Failed to fetch data");
      if (showLoader) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (locationId) {
      fetchData(locationId, dataType, deviceNo, initialLoad);
      const intervalId = setInterval(() => fetchData(locationId, dataType, deviceNo), 10000);
      return () => clearInterval(intervalId);
    }
  }, [locationId, dataType, deviceNo, initialLoad]);

  const handleButtonClick = (type) => {
    setDataType(type);
    setInitialLoad(true);
    fetchData(locationId, type, true);
    setInitialLoad(false);
  };

  return (
    <div style={{ padding: '20px' }}>
      <Grid container spacing={2} alignItems="center" justifyContent='center'>
        <Grid item xs={5} display={'flex'}>
          <BackButton style={{ marginTop: '5px', backgroundColor: 'red' }} />
          <Typography variant="h5" gutterBottom>
            Water Management
          </Typography>
        </Grid>
        <Grid item xs={7} container justifyContent="flex-end">
          {renderButtons(handleButtonClick, dataType)}
        </Grid>
        {isLoading ? (
          <Grid item xs={6} mt={10} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <CircularProgress />
            <Typography variant="h6" style={{ marginLeft: '10px' }}>{loadingMessage}</Typography>
          </Grid>
        ) : (
          <>
            {chartData.map((chart, index) => (
              <Grid item xs={6} key={index}>
                <Paper elevation={3} style={{ padding: 20, backgroundColor: 'rgba(3, 3, 62, 0.9)' }}>
                  <Typography variant="h6" gutterBottom style={getChartTitleStyle(chart, index)}>
                    {getChartTitle(chart, index)}
                  </Typography>
                  {renderChart(chart)}
                </Paper>
              </Grid>
            ))}
            {chartData2.map((chart, index) => (
              <Grid item xs={6} key={index}>
                <Paper elevation={3} style={{ padding: 20, backgroundColor: 'rgba(3, 3, 62, 0.9)' }}>
                  <Typography variant="h6" gutterBottom style={getChartTitleStyle(chart, index)}>
                    {getChartTitle(chart, index)}
                  </Typography>
                  {renderChart(chart)}
                </Paper>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Card elevation={3} style={{ padding: 20, backgroundColor: 'rgba(3, 3, 62, 0.9)' }}>
                <CardContent>
                  <Typography variant="h6" style={{ color: 'white', fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
                    Water Level Monitoring
                  </Typography>
                  <Typography variant="body1" style={{ color: 'white', fontFamily: 'Arial, sans-serif', marginTop: 10 }}>
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                      <div style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: pumpStatus ? 'green' : 'gray', marginRight: 10 }}>  </div>
                      Pump On
                    
                      <div style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: !pumpStatus ? 'red' : 'gray', marginLeft: 10,marginRight:10 }}>   </div>
                      Pump Off
                   
                    </div>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

function getChartTitle(chart, index) {
  if (chart.type === 'line') {
    switch (index) {
      default:
        return `Line Chart ${index + 1}`;
    }
  } else if (chart.type === "bar") {
    switch (index) {
      case 0:
        return "Water Flow";
      default:
        return `Bar Chart ${index + 1}`;
    }
  } else {
    return `Chart ${index + 1}`;
  }
}

function getChartTitleStyle(chart, index) {
  const defaultStyle = { color: 'white', fontFamily: 'Arial, sans-serif' };
  return { ...defaultStyle, fontWeight: 'bold' };
}

function renderChart(chart) {
  if (chart.type === 'bar') {
    const data = {
      labels: chart.xAxis,
      datasets: [
        {
          label: 'Water Flow',
          data: chart.data,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false, 
      scales: {
        x: {
          ticks: {
            color: 'white',
          },
        },
        y: {
          ticks: {
            color: 'white',
          },
        },
      },
      plugins: {
        legend: {
          labels: {
            color: 'white',
          },
        },
      },
    };

    return (
      <div style={{ width: '600px', height: '350px' }}>
        <Bar
          data={data}
          options={options}
        />
      </div>
    );
  }

}

function renderButtons(handleButtonClick, currentDataType) {
  const buttonLabels = ['Shift', 'Day', 'Week', 'Month', 'Quarter', 'Year'];
  return buttonLabels.map((label, index) => (
    <Button
      key={index}
      variant="contained"
      style={{ margin: '0px 2px', backgroundColor: currentDataType === label.toLowerCase() ? 'rgba(3, 3, 62, 0.9)' : 'gray' }}
      onClick={() => handleButtonClick(label.toLowerCase())}
    >
      {label}
    </Button>
  ));
}





