

// import React, { useEffect, useState } from "react";

// import {
//   Button,
//   Grid,
//   Paper,
//   Typography,
//   CircularProgress,
//   Select,
//   MenuItem,
//   FormControl,
//   InputLabel,
// } from "@mui/material";
// import { styled } from "@mui/material/styles";
// import { LineChart } from "@mui/x-charts/LineChart";

// import "../assets/css/emanagement.css";
// import { apiGetEnergyData } from "../api/api.getenergydata";
// import BackButton from "./backbutton";
// import { useLocation, useNavigate } from "react-router-dom";
// import { Line } from "react-chartjs-2";
// import {
//   BarChart,
//   Bar,
//   Rectangle,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";
// import { apiGetDevice } from "../api/api.getdevice";
// import { Form } from "react-bootstrap";

// const StyledSelect = styled(Select)(({ theme }) => ({
//   "& .MuiOutlinedInput-notchedOutline": {
//     borderColor:
//       theme.palette.mode === "light"
//         ? "rgba(0, 0, 0, 0.23)"
//         : "rgba(255, 255, 255, 0.23)",
//   },
//   "&:hover .MuiOutlinedInput-notchedOutline": {
//     borderColor:
//       theme.palette.mode === "light"
//         ? "rgba(0, 0, 0, 0.87)"
//         : "rgba(255, 255, 255, 0.87)",
//   },
//   "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//     borderColor: theme.palette.primary.main,
//   },
//   "& .MuiSelect-select": {
//     color: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff",
//     padding: "8px 14px",
//   },
//   "& .MuiInputBase-root": {
//     height: 40, 
//   },
// }));
// const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
//   transform: "translate(14px, 9px) scale(1)",
//   "&.MuiInputLabel-shrink": {
//     transform: "translate(14px, -6px) scale(0.75)",
//   },
// }));

// export default function EnergyManagement() {
//   const [energyManagementData, setEnergyManagementData] = useState([]);
//   const [refreshData, setRefreshData] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [error, setError] = useState(null);
//   const [severity, setSeverity] = useState("success");
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [intervalId, setIntervalId] = useState(null);
//   const [chartData, setChartData] = useState([]);
//   const [dataType, setDataType] = useState("shift");
//   const [isLoading, setIsLoading] = useState(false);
//   const [loadingMessage, setLoadingMessage] = useState("");
//   const location = useLocation();
//   const [initialLoad, setInitialLoad] = useState(true);
//   const [deviceNames, setDeviceNames] = useState([]);
//   const [deviceNo, setDeviceNo] = useState("");
//   const [voltageData, setVoltageData] = useState({});
//   const [currentData, setCurrentData] = useState({});
//   const [powerFactorData, setPowerFactorData] = useState({});
//   const [kvahData, setKvahData] = useState({});
//   const [kwhData, setKwhData] = useState([]);
//   const [locationData, setLocationData] = useState([]);
//   const [filteredDevices, setFilteredDevices] = useState([]);
//   const [deviceNos, setDeviceNos] = useState([]);
//   const [energyDevice1, setEnergyDevice1] = useState("");
//   const [energyDevice2, setEnergyDevice2] = useState("");
//   const [selectedDevice, setSelectedDevice] = useState("");

//   const { locationId, locationName } = location.state || {};
//   const navigate = useNavigate();

//   const handleSnackbarOpen = (message, severity) => {
//     setSnackbarMessage(message);
//     setSeverity(severity);
//     setOpenSnackbar(true);
//   };

//   console.log("location id em:", locationId, "location name em:", locationName);

//   useEffect(() => {
//     const getDeivce = async () => {
//       try {
//         const result = await apiGetDevice();
//         console.log("location data:", result.data.data); 
//         setLocationData(result.data.data); 
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getDeivce();
//   }, [refreshData]);
//   useEffect(() => {
//     if (locationName && locationData.length > 0) {
//       const filtered = locationData.filter(
//         (device) => device.lineName === locationName
//       );
//       setFilteredDevices(filtered);
//       if (filtered.length > 0) {
//         const deviceNosList = filtered.map((device) => device.deviceNo);
//         const deviceNamesList = filtered.map((device) => device.deviceName);

//         setDeviceNos(deviceNosList); 
//         setDeviceNames(deviceNamesList); 

      
//         const energy1Device = filtered.find(
//           (device) => device.deviceName === "Energy-1"
//         );
//         const energy2Device = filtered.find(
//           (device) => device.deviceName === "Energy-2"
//         );

//         setEnergyDevice1(energy1Device?.deviceNo || null);
//         setEnergyDevice2(energy2Device?.deviceNo || null);
//       }

//       console.log("device no and name ,:", deviceNos, deviceNames);
//       console.log(
//         "energy device 1 ,energy device 2:",
//         energyDevice1,
//         energyDevice2
//       );
//     }
//   }, [locationName, locationData]);

//   const handleDeviceChange = (event) => {
//     setSelectedDevice(event.target.value);
//     setDeviceNo(event.target.value);
//   };

//   const fetchData = async (
//     locationId,
//     dataType,
//     deviceNo,
//     showLoader = false
//   ) => {
//     try {
//       if (showLoader) {
//         setIsLoading(true);
//         setLoadingMessage(
//           `Loading ${dataType.charAt(0).toUpperCase() + dataType.slice(1)}`
//         );
//       }
//       const result = await apiGetEnergyData(locationId, dataType, deviceNo);
//       const chunkedData = result.data.data.slice(0, 15);
//       console.log("chunked data:", chunkedData);
//       if (chunkedData.length === 0) {
//         throw new Error("No data available");
//       }

//       const xAxisDates = chunkedData.map((item) => {
//         const dateParts = item.dateTimeRecvd.split(" ");
//         const datePart = dateParts[0];
//         const timePart = dateParts[1];
//         const [day, month, year] = datePart.split("-");
//         const [hours, minutes, seconds] = timePart.split(":");
//         const date = new Date(year, month - 1, day, hours, minutes, seconds);
//         return date;
//       });

//       console.log("newdate:", xAxisDates);

//       const sortedData = chunkedData.sort((a, b) => {
//         const dateA = new Date(
//           a.dateTimeRecvd.split(" ")[0].split("-").reverse().join("-") +
//             "T" +
//             a.dateTimeRecvd.split(" ")[1]
//         );
//         const dateB = new Date(
//           b.dateTimeRecvd.split(" ")[0].split("-").reverse().join("-") +
//             "T" +
//             b.dateTimeRecvd.split(" ")[1]
//         );
//         return dateA - dateB;
//       });

//       const xAxisLabels = sortedData.map((item) => {
//         const dateParts = item.dateTimeRecvd.split(" ");
//         const datePart = dateParts[0];
//         const timePart = dateParts[1];
//         const [day, month, year] = datePart.split("-");
//         const [hours, minutes, seconds] = timePart.split(":");
//         const date = new Date(year, month - 1, day, hours, minutes, seconds);
//         return date.toLocaleString();
//       });

//       console.log("xaxisdates", xAxisDates);
//       console.log("axislabel", xAxisLabels);

//       const formattedKwhData = sortedData.map((item) => ({
//         name: new Date(
//           item.dateTimeRecvd.split(" ")[0].split("-").reverse().join("-") +
//             "T" +
//             item.dateTimeRecvd.split(" ")[1]
//         ).toLocaleString(),
//         kwh: parseFloat(item.kwh),
//       }));

//       setKwhData(formattedKwhData);
//       setVoltageData({
//         labels: xAxisDates,
//         datasets: [
//           {
//             label: "Voltage (V)",
//             data: sortedData.map((item) => parseFloat(item.voltageB)),
//             fill: false,
//             borderColor: "rgba(0,0,255)",
//             tension: 0.1,
//           },
//           {
//             label: "Voltage (V)",
//             data: sortedData.map((item) => parseFloat(item.voltageY)),
//             fill: false,
//             borderColor: "rgba(255,255,0)",
//             tension: 0.1,
//           },
//           {
//             label: "Voltage (V)",
//             data: sortedData.map((item) => parseFloat(item.voltageR)),
//             fill: false,
//             borderColor: "rgba(255,0,0)",
//             tension: 0.1,
//           },
//         ],
//       });
//       setCurrentData({
//         labels: xAxisDates,
//         datasets: [
//           {
//             label: "Current (AMP)",
//             data: sortedData.map((item) => parseFloat(item.currentB)),
//             fill: false,
//             borderColor: "rgb(0,0,255)",
//             tension: 0.1,
//           },
//           {
//             data: sortedData.map((item) => parseFloat(item.currentY)),
//             fill: false,
//             borderColor: "rgb(255,255,0)",
//             tension: 0.1,
//           },
//           {
//             data: sortedData.map((item) => parseFloat(item.currentR)),
//             fill: false,
//             borderColor: "rgb(255,0,0)",
//             tension: 0.1,
//           },
//         ],
//       });

//       setPowerFactorData({
//         labels: xAxisDates,
//         datasets: [
//           {
//             label: "Power Factor ",
//             data: sortedData.map((item) => parseFloat(item.pf)),
//             fill: false,
//             borderColor: "	rgb(255,20,147)",
//             tension: 0.1,
//           },
//         ],
//       });
//       setKvahData({
//         labels: xAxisDates,
//         datasets: [
//           {
//             label: "KVAH ",
//             data: sortedData.map((item) => parseFloat(item.kvah)),
//             fill: false,
//             borderColor: "	rgb(255,140,0)",
//             tension: 0.1,
//           },
//         ],
//       });

//       const updatedChartData = [
//         {
//           type: "bar",
//           xAxis: xAxisLabels,
//           data: sortedData.map((item) => Number(item.kwh)),
//           label: "kWH",
//         },
//       ];

//       console.log("updated chart data:", updatedChartData);
//       setEnergyManagementData(chunkedData);
//       setChartData(updatedChartData);

//       if (showLoader) {
//         setIsLoading(false);
//       }
//     } catch (error) {
//       console.error("Error fetching energy data:", error);
//       setError(error);
//       if (showLoader) {
//         setIsLoading(false);
//       }
//     }
//   };

//   useEffect(() => {
//     if (locationId) {
//       fetchData(locationId, dataType, deviceNo, initialLoad);
//       const intervalId = setInterval(
//         () => fetchData(locationId, dataType, deviceNo),
//         10000
//       );
//       return () => clearInterval(intervalId);
//     }
//   }, [locationId, dataType, deviceNo, initialLoad]);

//   const handleChartClick = (chartType, chartName) => {
//     const clickedChart = chartData.find((chart) => chart.type === chartType);
//     navigate("/fullviewchart", {
//       state: { locationId, chartType, dataType, chartName, clickedChart },
//     });
//   };

//   const handleButtonClick = (type) => {
//     setDataType(type);
//     setInitialLoad(true);
//     fetchData(locationId, type, deviceNo, true);
//     setInitialLoad(false);
//   };
//   const options = {
//     scales: {
//       x: {
//         type: "time",
//         time: {
//           unit: "minute",
//         },
//         title: {
//           display: true,
//           text: "Time",
//           color: "white",
//         },
//         ticks: {
//           color: "white",
//         },
//         grid: {
//           color: "black",
//         },
//       },
//       y: {
//         title: {
//           display: true,
//           text: "Value",
//           color: "white",
//         },
//         ticks: {
//           color: "white",
//         },
//         grid: {
//           color: "black",
//         },
//       },
//     },
//     plugins: {
//       legend: {
//         display: false,
//       },
//       label: {
//         display: false,
//       },
//       zoom: {
//         pan: {
//           enabled: true,
//           mode: "xy",
//         },
//         zoom: {
//           wheel: {
//             enabled: true,
//           },
//           pinch: {
//             enabled: true,
//           },
//           mode: "xy",
//         },
//       },
//     },
//   };

//   return (
//     <div style={{ padding: "20px" }}>
//       <Grid container spacing={2} alignItems="center">
//         <Grid item xs={3} display={"flex"}>
//           <BackButton />
//           <Typography variant="h5" gutterBottom>
//             Energy Management
//           </Typography>
//         </Grid>
//         <Grid item xs={3}>
//           <FormControl fullWidth variant="outlined" size="small">
//             <StyledInputLabel id="device-select-label">
//               Select Device
//             </StyledInputLabel>
//             <StyledSelect
//               labelId="device-select-label"
//               id="device-select"
//               value={selectedDevice}
//               onChange={handleDeviceChange}
//               label="Select Device"
//             >
//               <MenuItem value="">
//                 <em>None</em>
//               </MenuItem>
//               {energyDevice1 && (
//                 <MenuItem value={energyDevice1}>
//                   Energy-1: {energyDevice1}
//                 </MenuItem>
//               )}
//               {energyDevice2 && (
//                 <MenuItem value={energyDevice2}>
//                   Energy-2: {energyDevice2}
//                 </MenuItem>
//               )}
//             </StyledSelect>
//           </FormControl>
//         </Grid>
//         <Grid item xs={6} container justifyContent="flex-end">
//           {renderButtons(handleButtonClick, dataType)}
//         </Grid>

//         {isLoading ? (
//           <Grid
//             item
//             xs={12}
//             display="flex"
//             justifyContent="center"
//             alignItems="center"
//             style={{ height: "300px" }}
//           >
//             <div style={{ textAlign: "center" }}>
//               <CircularProgress />
//               <Typography variant="h6" style={{ marginTop: "20px" }}>
//                 {loadingMessage}
//               </Typography>
//             </div>
//           </Grid>
//         ) : (
//           <>
//             <Grid item xs={4} style={{ cursor: "pointer" }}>
//               <Paper
//                 elevation={3}
//                 style={{ padding: 20, backgroundColor: "rgba(3, 3, 62, 0.9)" }}
//               >
//                 <Typography
//                   variant="h6"
//                   align="center"
//                   gutterBottom
//                   style={{ color: "white" }}
//                 >
//                   Voltage (V)
//                 </Typography>
//                 {voltageData.datasets ? (
//                   <Line data={voltageData} options={options} />
//                 ) : (
//                   <Typography variant="body1" align="center">
//                     No temperature data available
//                   </Typography>
//                 )}
//               </Paper>
//             </Grid>
//             <Grid item xs={4} style={{ cursor: "pointer" }}>
//               <Paper
//                 elevation={3}
//                 style={{ padding: 20, backgroundColor: "rgba(3, 3, 62, 0.9)" }}
//               >
//                 <Typography
//                   variant="h6"
//                   align="center"
//                   gutterBottom
//                   style={{ color: "white" }}
//                 >
//                   Current (Amp)
//                 </Typography>
//                 {currentData.datasets ? (
//                   <Line data={currentData} options={options} />
//                 ) : (
//                   <Typography variant="body1" align="center">
//                     No temperature data available
//                   </Typography>
//                 )}
//               </Paper>
//             </Grid>
//             <Grid item xs={4} style={{ cursor: "pointer" }}>
//               <Paper
//                 elevation={3}
//                 style={{ padding: 20, backgroundColor: "rgba(3, 3, 62, 0.9)" }}
//               >
//                 <Typography
//                   variant="h6"
//                   align="center"
//                   gutterBottom
//                   style={{ color: "white" }}
//                 >
//                   KWH
//                 </Typography>
//                 {kwhData.length > 0 ? (
//                   <ResponsiveContainer width="100%" height={222}>
//                     <BarChart
//                       width={500}
//                       height={225}
//                       data={kwhData}
//                       margin={{
//                         top: 5,
//                         right: 30,
//                         left: 20,
//                         bottom: 5,
//                       }}
//                     >
//                       <CartesianGrid strokeDasharray="3 3" />
//                       <XAxis
//                         dataKey="name"
//                         tick={{ fill: "white", fontSize: "12px" }}
//                       />
//                       <YAxis tick={{ fill: "white", fontSize: "12px" }} />
//                       <Tooltip />
//                       <Legend />
//                       <Bar
//                         dataKey="kwh"
//                         fill="#8884d8"
//                         activeBar={<Rectangle fill="pink" stroke="blue" />}
//                       />
//                     </BarChart>
//                   </ResponsiveContainer>
//                 ) : (
//                   <Typography variant="body1" align="center">
//                     No kWh data available
//                   </Typography>
//                 )}
//               </Paper>
//             </Grid>

//             <Grid item xs={4} style={{ cursor: "pointer" }}>
//               <Paper
//                 elevation={3}
//                 style={{ padding: 20, backgroundColor: "rgba(3, 3, 62, 0.9)" }}
//               >
//                 <Typography
//                   variant="h6"
//                   align="center"
//                   gutterBottom
//                   style={{ color: "white" }}
//                 >
//                   Power Factor
//                 </Typography>
//                 {powerFactorData.datasets ? (
//                   <Line data={powerFactorData} options={options} />
//                 ) : (
//                   <Typography variant="body1" align="center">
//                     No temperature data available
//                   </Typography>
//                 )}
//               </Paper>
//             </Grid>
//             <Grid item xs={4} style={{ cursor: "pointer" }}>
//               <Paper
//                 elevation={3}
//                 style={{ padding: 20, backgroundColor: "rgba(3, 3, 62, 0.9)" }}
//               >
//                 <Typography
//                   variant="h6"
//                   align="center"
//                   gutterBottom
//                   style={{ color: "white" }}
//                 >
//                   KVAH
//                 </Typography>
//                 {kvahData.datasets ? (
//                   <Line data={kvahData} options={options} />
//                 ) : (
//                   <Typography variant="body1" align="center">
//                     No temperature data available
//                   </Typography>
//                 )}
//               </Paper>
//             </Grid>
//           </>
//         )}
//       </Grid>
//     </div>
//   );
// }

// function renderButtons(handleButtonClick, currentDataType) {
//   const buttonLabels = ["Shift", "Day", "Week", "Month", "Quarter", "Year"];
//   return buttonLabels.map((label, index) => (
//     <Button
//       key={index}
//       variant="contained"
//       style={{
//         margin: "0px 2px",
//         backgroundColor:
//           currentDataType === label.toLowerCase()
//             ? "rgba(3, 3, 62, 0.9)"
//             : "gray",
//       }}
//       onClick={() => handleButtonClick(label.toLowerCase())}
//     >
//       {label}
//     </Button>
//   ));
// }


//---------------------------------------------------------//







import React, { useEffect, useState } from "react";

import {
  Button,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LineChart } from "@mui/x-charts/LineChart";

import "../assets/css/emanagement.css";
import { apiGetEnergyData } from "../api/api.getenergydata";
import BackButton from "./backbutton";
import { useLocation, useNavigate } from "react-router-dom";
import { Line } from "react-chartjs-2";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { apiGetDevice } from "../api/api.getdevice";
import { Form } from "react-bootstrap";

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor:
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, 0.23)"
        : "rgba(255, 255, 255, 0.23)",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor:
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, 0.87)"
        : "rgba(255, 255, 255, 0.87)",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.main,
  },
  "& .MuiSelect-select": {
    color: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff",
    padding: "8px 14px",
  },
  "& .MuiInputBase-root": {
    height: 40, 
  },
}));
const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  transform: "translate(14px, 9px) scale(1)",
  "&.MuiInputLabel-shrink": {
    transform: "translate(14px, -6px) scale(0.75)",
  },
}));

export default function EnergyManagement() {
  const [energyManagementData, setEnergyManagementData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [dataType, setDataType] = useState("shift");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const location = useLocation();
  const [initialLoad, setInitialLoad] = useState(true);
  const [deviceNames, setDeviceNames] = useState([]);
  const [deviceNo, setDeviceNo] = useState("");
  const [voltageData, setVoltageData] = useState({});
  const [currentData, setCurrentData] = useState({});
  const [powerFactorData, setPowerFactorData] = useState({});
  const [kvahData, setKvahData] = useState({});
  const [kwhData, setKwhData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [deviceNos, setDeviceNos] = useState([]);
  const [energyDevice1, setEnergyDevice1] = useState("");
  const [energyDevice2, setEnergyDevice2] = useState("");
  const [selectedDevice, setSelectedDevice] = useState("");

  const { locationId, locationName } = location.state || {};
  const navigate = useNavigate();

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  console.log("location id em:", locationId, "location name em:", locationName);

  useEffect(() => {
    const getDeivce = async () => {
      try {
        const result = await apiGetDevice();
        console.log("location data:", result.data.data); 
        setLocationData(result.data.data); 
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getDeivce();
  }, [refreshData]);
  useEffect(() => {
    if (locationName && locationData.length > 0) {
      const filtered = locationData.filter(
        (device) => device.lineName === locationName
      );
      setFilteredDevices(filtered);
      if (filtered.length > 0) {
        const deviceNosList = filtered.map((device) => device.deviceNo);
        const deviceNamesList = filtered.map((device) => device.deviceName);

        setDeviceNos(deviceNosList); 
        setDeviceNames(deviceNamesList); 

      
        const energy1Device = filtered.find(
          (device) => device.deviceName === "Energy-1"
        );
        const energy2Device = filtered.find(
          (device) => device.deviceName === "Energy-2"
        );

        setEnergyDevice1(energy1Device?.deviceNo || null);
        setEnergyDevice2(energy2Device?.deviceNo || null);
      }

      console.log("device no and name ,:", deviceNos, deviceNames);
      console.log(
        "energy device 1 ,energy device 2:",
        energyDevice1,
        energyDevice2
      );
    }
  }, [locationName, locationData]);

  const handleDeviceChange = (event) => {
    setSelectedDevice(event.target.value);
    setDeviceNo(event.target.value);
  };

  const fetchData = async (
    locationId,
    dataType,
    deviceNo,
    showLoader = false
  ) => {
    try {
      if (showLoader) {
        setIsLoading(true);
        setLoadingMessage(
          `Loading ${dataType.charAt(0).toUpperCase() + dataType.slice(1)}`
        );
      }
      const result = await apiGetEnergyData(locationId, dataType, deviceNo);
      const chunkedData = result.data.data.slice(0, 15);
      console.log("chunked data:", chunkedData);
      if (chunkedData.length === 0) {
        throw new Error("No data available");
      }

      const xAxisDates = chunkedData.map((item) => {
        const dateParts = item.dateTimeRecvd.split(" ");
        const datePart = dateParts[0];
        const timePart = dateParts[1];
        const [day, month, year] = datePart.split("-");
        const [hours, minutes, seconds] = timePart.split(":");
        const date = new Date(year, month - 1, day, hours, minutes, seconds);
        return date;
      });

      console.log("newdate:", xAxisDates);

      const sortedData = chunkedData.sort((a, b) => {
        const dateA = new Date(
          a.dateTimeRecvd.split(" ")[0].split("-").reverse().join("-") +
            "T" +
            a.dateTimeRecvd.split(" ")[1]
        );
        const dateB = new Date(
          b.dateTimeRecvd.split(" ")[0].split("-").reverse().join("-") +
            "T" +
            b.dateTimeRecvd.split(" ")[1]
        );
        return dateA - dateB;
      });

      const xAxisLabels = sortedData.map((item) => {
        const dateParts = item.dateTimeRecvd.split(" ");
        const datePart = dateParts[0];
        const timePart = dateParts[1];
        const [day, month, year] = datePart.split("-");
        const [hours, minutes, seconds] = timePart.split(":");
        const date = new Date(year, month - 1, day, hours, minutes, seconds);
        return date.toLocaleString();
      });

      console.log("xaxisdates", xAxisDates);
      console.log("axislabel", xAxisLabels);

      const formattedKwhData = sortedData.map((item) => ({
        name: new Date(
          item.dateTimeRecvd.split(" ")[0].split("-").reverse().join("-") +
            "T" +
            item.dateTimeRecvd.split(" ")[1]
        ).toLocaleString(),
        kwh: parseFloat(item.kwh),
      }));

      setKwhData(formattedKwhData);
      setVoltageData({
        labels: xAxisDates,
        datasets: [
          {
            label: "Voltage (V)",
            data: sortedData.map((item) => parseFloat(item.voltageB)),
            fill: false,
            borderColor: "rgba(0,0,255)",
            tension: 0.1,
          },
          {
            label: "Voltage (V)",
            data: sortedData.map((item) => parseFloat(item.voltageY)),
            fill: false,
            borderColor: "rgba(255,255,0)",
            tension: 0.1,
          },
          {
            label: "Voltage (V)",
            data: sortedData.map((item) => parseFloat(item.voltageR)),
            fill: false,
            borderColor: "rgba(255,0,0)",
            tension: 0.1,
          },
        ],
      });
      setCurrentData({
        labels: xAxisDates,
        datasets: [
          {
            label: "Current (AMP)",
            data: sortedData.map((item) => parseFloat(item.currentB)),
            fill: false,
            borderColor: "rgb(0,0,255)",
            tension: 0.1,
          },
          {
            data: sortedData.map((item) => parseFloat(item.currentY)),
            fill: false,
            borderColor: "rgb(255,255,0)",
            tension: 0.1,
          },
          {
            data: sortedData.map((item) => parseFloat(item.currentR)),
            fill: false,
            borderColor: "rgb(255,0,0)",
            tension: 0.1,
          },
        ],
      });

      setPowerFactorData({
        labels: xAxisDates,
        datasets: [
          {
            label: "Power Factor ",
            data: sortedData.map((item) => parseFloat(item.pf)),
            fill: false,
            borderColor: "	rgb(255,20,147)",
            tension: 0.1,
          },
        ],
      });
      setKvahData({
        labels: xAxisDates,
        datasets: [
          {
            label: "KVAH ",
            data: sortedData.map((item) => parseFloat(item.kvah)),
            fill: false,
            borderColor: "	rgb(255,140,0)",
            tension: 0.1,
          },
        ],
      });

      const updatedChartData = [
        {
          type: "bar",
          xAxis: xAxisLabels,
          data: sortedData.map((item) => Number(item.kwh)),
          label: "kWH",
        },
      ];

      console.log("updated chart data:", updatedChartData);
      setEnergyManagementData(chunkedData);
      setChartData(updatedChartData);

      if (showLoader) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching energy data:", error);
      setError(error);
      if (showLoader) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (locationId) {
      fetchData(locationId, dataType, deviceNo, initialLoad);
      const intervalId = setInterval(
        () => fetchData(locationId, dataType, deviceNo),
        10000
      );
      return () => clearInterval(intervalId);
    }
  }, [locationId, dataType, deviceNo, initialLoad]);

  const handleChartClick = (chartType, chartName) => {
    const clickedChart = chartData.find((chart) => chart.type === chartType);
    navigate("/fullviewchart", {
      state: { locationId, chartType, dataType, chartName, clickedChart },
    });
  };

  const handleButtonClick = (type) => {
    setDataType(type);
    setInitialLoad(true);
    fetchData(locationId, type, deviceNo, true);
    setInitialLoad(false);
  };
  const options = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "minute",
        },
        title: {
          display: true,
          text: "Time",
          color: "white",
        },
        ticks: {
          color: "white",
        },
        grid: {
          color: "black",
        },
      },
      y: {
        title: {
          display: true,
          text: "Value",
          color: "white",
        },
        ticks: {
          color: "white",
        },
        grid: {
          color: "black",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      label: {
        display: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "xy",
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "xy",
        },
      },
    },
  };

  return (
    <div style={{ padding: "20px" }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3} display={"flex"}>
          <BackButton />
          <Typography variant="h5" gutterBottom>
            Energy Management
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth variant="outlined" size="small">
            <StyledInputLabel id="device-select-label">
              Select Device
            </StyledInputLabel>
            <StyledSelect
              labelId="device-select-label"
              id="device-select"
              value={selectedDevice}
              onChange={handleDeviceChange}
              label="Select Device"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {energyDevice1 && (
                <MenuItem value={energyDevice1}>
                  Energy-1: {energyDevice1}
                </MenuItem>
              )}
              {energyDevice2 && (
                <MenuItem value={energyDevice2}>
                  Energy-2: {energyDevice2}
                </MenuItem>
              )}
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          {renderButtons(handleButtonClick, dataType)}
        </Grid>

        {isLoading ? (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: "300px" }}
          >
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
              <Typography variant="h6" style={{ marginTop: "20px" }}>
                {loadingMessage}
              </Typography>
            </div>
          </Grid>
        ) : (
          <>
            <Grid item xs={4} style={{ cursor: "pointer" }}>
              <Paper
                elevation={3}
                style={{ padding: 20, backgroundColor: "rgba(3, 3, 62, 0.9)" }}
              >
                <Typography
                  variant="h6"
                  align="center"
                  gutterBottom
                  style={{ color: "white" }}
                >
                  Voltage (V)
                </Typography>
                {voltageData.datasets ? (
                  <Line data={voltageData} options={options} />
                ) : (
                  <Typography variant="body1" align="center">
                    No temperature data available
                  </Typography>
                )}
              </Paper>
            </Grid>
            <Grid item xs={4} style={{ cursor: "pointer" }}>
              <Paper
                elevation={3}
                style={{ padding: 20, backgroundColor: "rgba(3, 3, 62, 0.9)" }}
              >
                <Typography
                  variant="h6"
                  align="center"
                  gutterBottom
                  style={{ color: "white" }}
                >
                  Current (Amp)
                </Typography>
                {currentData.datasets ? (
                  <Line data={currentData} options={options} />
                ) : (
                  <Typography variant="body1" align="center">
                    No temperature data available
                  </Typography>
                )}
              </Paper>
            </Grid>
            <Grid item xs={4} style={{ cursor: "pointer" }}>
              <Paper
                elevation={3}
                style={{ padding: 20, backgroundColor: "rgba(3, 3, 62, 0.9)" }}
              >
                <Typography
                  variant="h6"
                  align="center"
                  gutterBottom
                  style={{ color: "white" }}
                >
                  KWH
                </Typography>
                {kwhData.length > 0 ? (
                  <ResponsiveContainer width="100%" height={222}>
                    <BarChart
                      width={500}
                      height={225}
                      data={kwhData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="name"
                        tick={{ fill: "white", fontSize: "12px" }}
                      />
                      <YAxis tick={{ fill: "white", fontSize: "12px" }} />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey="kwh"
                        fill="#8884d8"
                        activeBar={<Rectangle fill="pink" stroke="blue" />}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <Typography variant="body1" align="center">
                    No kWh data available
                  </Typography>
                )}
              </Paper>
            </Grid>

            <Grid item xs={4} style={{ cursor: "pointer" }}>
              <Paper
                elevation={3}
                style={{ padding: 20, backgroundColor: "rgba(3, 3, 62, 0.9)" }}
              >
                <Typography
                  variant="h6"
                  align="center"
                  gutterBottom
                  style={{ color: "white" }}
                >
                  Power Factor
                </Typography>
                {powerFactorData.datasets ? (
                  <Line data={powerFactorData} options={options} />
                ) : (
                  <Typography variant="body1" align="center">
                    No temperature data available
                  </Typography>
                )}
              </Paper>
            </Grid>
            <Grid item xs={4} style={{ cursor: "pointer" }}>
              <Paper
                elevation={3}
                style={{ padding: 20, backgroundColor: "rgba(3, 3, 62, 0.9)" }}
              >
                <Typography
                  variant="h6"
                  align="center"
                  gutterBottom
                  style={{ color: "white" }}
                >
                  KVAH
                </Typography>
                {kvahData.datasets ? (
                  <Line data={kvahData} options={options} />
                ) : (
                  <Typography variant="body1" align="center">
                    No temperature data available
                  </Typography>
                )}
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

function renderButtons(handleButtonClick, currentDataType) {
  const buttonLabels = ["Shift", "Day", "Week", "Month", "Quarter", "Year"];
  return buttonLabels.map((label, index) => (
    <Button
      key={index}
      variant="contained"
      style={{
        margin: "0px 2px",
        backgroundColor:
          currentDataType === label.toLowerCase()
            ? "rgba(3, 3, 62, 0.9)"
            : "gray",
      }}
      onClick={() => handleButtonClick(label.toLowerCase())}
    >
      {label}
    </Button>
  ));
}
